// CodeEditor.tsx
import React, { useState, useEffect } from 'react';
import Editor from '@monaco-editor/react';

interface CodeEditorProps {
  code: string;
  onSQLResponse: (sqlCode: string, result: any) => void; // Callback to handle SQL result
  onSaveCode: (code: string) => void; // Callback to handle saving code
  codeExplanationMap: { [key: string]: string };
  sqlDataMap: { [sqlCode: string]: any }; // New prop for the mapping
}

const CodeEditor: React.FC<CodeEditorProps> = ({
  code,
  onSQLResponse,
  onSaveCode,
  codeExplanationMap,
  sqlDataMap,
}) => {
  const [currentSqlCode, setCurrentSqlCode] = useState(code);

  // Sync currentSqlCode with the code prop when it changes
  useEffect(() => {
    setCurrentSqlCode(code);
  }, [code]);

  // Function to handle query execution
  const ExecuteSqlQuery = () => {
    console.log('Executing SQL query with the following code:');
    console.log(currentSqlCode);

    fetch('http://localhost:8000/run-query', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ query: currentSqlCode }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.error('SQL Execution Error:', data.error);
        } else {
          console.log('SQL Query Result:', data);
          // Pass the executed SQL query and result back to the parent component (App)
          onSQLResponse(currentSqlCode, data);

          // Automatically save the current SQL code to savedCodeArray
          onSaveCode(currentSqlCode);
        }
      })
      .catch((error) => {
        console.error('Error executing SQL query:', error);
      });
  };

  // Optional: Display the data associated with the current SQL code
  const currentData = sqlDataMap[currentSqlCode];

  return (
    <div
      style={{
        height: '300px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '10px',
      }}
    >
      {/* Editor component from monaco-editor */}
      <Editor
        height="80%"
        defaultLanguage="sql"
        value={currentSqlCode}
        onChange={(value) => setCurrentSqlCode(value || '')}
      />
      {/* Run button */}
      <div style={{ marginTop: '10px' }}>
        <button
          onClick={ExecuteSqlQuery}
          style={{
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Run
        </button>
      </div>
    </div>
  );
};

export default CodeEditor;
