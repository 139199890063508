// sheets.tsx
import { Workbook, WorkbookInstance } from '@fortune-sheet/react';
import '@fortune-sheet/react/dist/index.css';
import ChatBot from './ChatBot';
import CodeEditor from './CodeEditor';
import React, { useRef, useState } from 'react';

type Row = { [key: string]: any };

export function App() {
  const [sqlCode, setSqlCode] = useState<string>('');
  const [workbookData, setWorkbookData] = useState<any[]>([
    { name: 'Sheet1', data: [] },
  ]);
  const [savedCodeArray, setSavedCodeArray] = useState<string[]>([]);
  const [codeExplanationMap, setCodeExplanationMap] = useState<{ [key: string]: string }>({});
  const [sqlDataMap, setSqlDataMap] = useState<{ [sqlCode: string]: any }>({});

  const workbookRef = useRef<WorkbookInstance>(null);

  const settings = {
    row: 700
  }
  // Function to handle SQL responses generated by OpenAI and update the CodeEditor
  const HandleOpenAiGeneratedSqlResponse = (generatedSqlCode: string) => {
    setSqlCode(generatedSqlCode);
  };

  // Function to map the SQL result to Workbook-compatible format
  const mapSqlResultToWorkbook = (result: any): [string[], Row[]] | null => {
    if (!result || !Array.isArray(result) || result.length === 0) return null;

    const headers = Object.keys(result[0]);
    const rows = result.map((row: any) => {
      const rowData: Row = {};
      headers.forEach((header) => {
        rowData[header] = row[header];
      });
      return rowData;
    });

    return [headers, rows];
  };

  // Function to handle SQL result from the CodeEditor and populate the Workbook
  const HandleCodeEditorSqlResult = (executedSqlCode: string, result: any) => {
    console.log('Executed SQL Code:', executedSqlCode);
    console.log('SQL Execution Result:', result);

    // Update the mapping of sqlCode to data
    setSqlDataMap((prevMap) => ({
      ...prevMap,
      [executedSqlCode]: result,
    }));

    // Map the SQL result to the Workbook data structure
    const mappedResult = mapSqlResultToWorkbook(result);
    if (!mappedResult) {
      return;
    }

    const [headers, rows] = mappedResult;

    if (workbookRef.current) {
      const sheet = workbookRef.current.getSheet();
      sheet.celldata.forEach((c_data) => {
        workbookRef.current?.setCellValue(c_data.r, c_data.c, null);
      });

      // Set headers
      headers.forEach((header, colIdx) => {
        workbookRef.current!.setCellValue(0, colIdx, header, { index: 0 });
      });
      // Set data rows
      rows.forEach((rowData, rowIdx) => {
        headers.forEach((header, colIdx) => {
          const cellValue = rowData[header];
          workbookRef.current!.setCellValue(rowIdx + 1, colIdx, cellValue, { index: 0 });
        });
      });
    }
  };

  // Function to handle saving code from CodeEditor
  const handleSaveCode = (code: string) => {
    setSavedCodeArray((prevArray) => [...prevArray, code]);
  };

  // Function to handle new code explanation and SQL code mapping
  const handleNewCodeExplanationMapping = (codeExplanation: string, sqlCode: string) => {
    setCodeExplanationMap((prevMap) => ({
      ...prevMap,
      [codeExplanation]: sqlCode,
    }));
  };

  return (
    <div className="sheet">
      {/* Fortune Sheet Workbook Component */}
      <Workbook ref={workbookRef} data={workbookData} {...settings}/>

      <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', marginTop: '20px' }}>
        {/* ChatBot Component */}
        <div style={{ flex: 1 }}>
          <ChatBot
            onSQLResponse={HandleOpenAiGeneratedSqlResponse}
            savedCodeArray={savedCodeArray}
            currentCode={sqlCode}
            workbookRef={workbookRef}
            onNewCodeExplanationMapping={handleNewCodeExplanationMapping}
            codeExplanationMap={codeExplanationMap}
            sqlDataMap={sqlDataMap} // Pass the mapping if needed
          />
        </div>

        {/* CodeEditor Component */}
        <div style={{ flex: 1 }}>
          <CodeEditor
            code={sqlCode}
            onSQLResponse={HandleCodeEditorSqlResult}
            onSaveCode={handleSaveCode}
            codeExplanationMap={codeExplanationMap}
            sqlDataMap={sqlDataMap} // Pass the mapping if needed
          />
        </div>
      </div>
    </div>
  );
}

export default App;
